































import StatsCard from '@/components/StatsCard.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';
import SplineAreaChart from '@/components/SplineAreaChart.vue';
import {Interactions} from '@/domain/model/types';
import ChartLine from '@/components/ChartLine.vue';
import {formatMonthDay} from '@/utils/helpers';
import {dashboardStore} from '@/store/modules/dashboard';
import {ChartData, ChartDataSets} from "chart.js";

@Component({
  name: 'interactions-card',
  components: {ChartLine, SplineAreaChart, StatsCard}
})
export default class InteractionsCard extends Vue {
  @Prop() itemWeek!: Array<Interactions | null>
  @Prop() itemPrevWeek!: Array<Interactions | null>

  weekdays: string[] = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

  get dataPoints() {
    const points: Array<number | null> = []
    for (let i = 0; i < this.itemWeek.length; i++) {
      const item = this.itemWeek[i];
      points.push(item !== null ? item.openedCases : null)
    }
    return points
  }

  get dataPointsPrev() {
    const points: Array<number | null> = []
    for (let i = 0; i < this.itemPrevWeek.length; i++) {
      const item = this.itemPrevWeek[i];
      points.push(item !== null ? item.openedCases : null)
    }
    return points
  }

  get tooltips() {
    const tips: Array<string[] | null> = []
    for (let i = 0; i < this.itemWeek.length; i++) {
      const item = this.itemWeek[i];
      tips.push(item !== null ? [formatMonthDay(item.createdDate?.toDate()), `${item.openedCases}`] : null)
    }
    return tips
  }

  get tooltipsPrev() {
    const tips: Array<string[] | null> = []
    for (let i = 0; i < this.itemPrevWeek.length; i++) {
      const item = this.itemPrevWeek[i];
      tips.push(item !== null ? [formatMonthDay(item.createdDate?.toDate()), `${item.openedCases}`] : null)
    }
    return tips
  }

  get totalWeekCases() {
    return dashboardStore.totalCasesWeek
  }

  get totalPrevWeekCases() {
    return dashboardStore.totalCasesPrevWeek
  }

  get dynamics() {
    return dashboardStore.casesDynamics
  }

  get casesDifference() {
    return `${this.dynamics}%`
  }

  get isPositive() {
    return this.dynamics >= 0
  }

  get iconDynamic() {
    return this.isPositive ? '_ic_arrow_dyn_pos.svg' : '_ic_arrow_dyn_neg.svg'
  }

  get chartData() {
    const ds1: ChartDataSets = {
      backgroundColor: '#88DE31',
      borderColor: '#88DE31',
      borderWidth: 4,
      data: this.dataPoints,
      fill: false,
      pointHoverBackgroundColor: '#88DE31',
      pointHoverBorderColor: '#fff',
      pointHoverRadius: 6,
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 3
    }
    const ds2: ChartDataSets = {
      borderColor: '#3773F5',
      borderWidth: 4,
      data: this.dataPointsPrev,
      fill: false,
      pointHoverBackgroundColor: '#3773F5',
      pointHoverBorderColor: '#fff',
      pointHoverRadius: 6,
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 3
    }
    return {
      labels: this.weekdays,
      datasets: [ds1, ds2] as ChartDataSets[]
    } as ChartData
  }
};
