











import StatsCard from '@/components/StatsCard.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';
import DoughnutPieChart from '@/components/DoughnutPieChart.vue';
import {CasesCompletion} from '@/domain/model/types';

@Component({
  name: 'completion-card',
  components: {DoughnutPieChart, StatsCard}
})
export default class CompletionCard extends Vue {
  @Prop() readonly item!: CasesCompletion

  get dataPoints() {
    const {opened, closed} = this.item
    return [
      {y: closed, color: '#88DE31', title: 'Closed', class: 'one', tipValue: `${(100 * closed / opened).toFixed(0)}%`},
      {
        y: opened - closed,
        color: '#3773F5',
        title: 'Ongoing',
        class: 'two',
        tipValue: `${(100 * (1 - closed / opened)).toFixed()}%`
      }
    ];
  }
};
