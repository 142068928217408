







import {Component, Prop, Vue} from 'vue-property-decorator';
import Chart from 'chart.js';

@Component({
  name: 'spline-area-chart'
})
export default class SplineAreaChart extends Vue {
  @Prop({default: []}) labels!: string[];
  @Prop({default: []}) dataPoints!: any[];
  @Prop({required: true}) chartId!: string;
  @Prop({required: true}) title!: string;
  @Prop({required: true}) subtitle!: string;
  @Prop({default: false}) negative!: boolean;

  mounted() {
    const ctx = (this.$refs[this.chartId] as HTMLCanvasElement).getContext('2d');
    const gradient = ctx!.createLinearGradient(0, 0, 0, 90);
    gradient.addColorStop(0, 'rgba(55,115,245,0.8)');
    gradient.addColorStop(0.3, 'rgba(55,115,245,0.25)');
    gradient.addColorStop(1, 'rgba(55,115,245,0)');

    const data: any = {
      labels: this.labels,
      datasets: [{
        label: '#',
        backgroundColor: gradient,
        borderColor: '#3773F5',
        borderWidth: 3,
        data: this.dataPoints
      }]
    };

    const options: any = {
      responsive: true,
      maintainAspectRatio: true,
      animation: {
        easing: 'easeInOutQuad',
        duration: 500
      },
      axes: {
        display: false
      },
      scales: {
        xAxes: [{
          display: false,
          gridLines: {
            color: 'rgba(0, 0, 0, 0)',
            lineWidth: 0
          }
        }],
        yAxes: [{
          display: false,
          gridLines: {
            color: 'rgba(0, 0, 0, 0)',
            lineWidth: 0
          }
        }]
      },
      elements: {
        line: {
          tension: 0.4
        },
        point: {
          radius: 0,
          borderWidth: 0,
        }
      },
      legend: {
        display: false
      },
      point: {
        backgroundColor: 'white'
      },
      tooltips: {
        titleFontFamily: 'Open Sans',
        backgroundColor: 'rgba(0,0,0,0.3)',
        titleFontColor: 'red',
        caretSize: 5,
        cornerRadius: 2,
        xPadding: 10,
        yPadding: 10
      }
    };

    const chart = new Chart(ctx!, {
      type: 'line',
      data,
      options
    });
  }
};
