















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Chart from 'chart.js';

@Component({
  name: 'doughnut-pie-chart'
})
export default class DoughnutPieChart extends Vue {
  @Prop({required: true}) readonly chartId!: string;
  @Prop({
    default: () => {
      return []
    }
  }) readonly data?: any[];
  @Prop() readonly totalCount?: number

  chart: any = null

  get labels() {
    return this.data!.map((point) => point.title);
  }

  get values() {
    return this.data!.map((point) => point.tipValue);
  }

  get dataset() {
    return this.data!.map((point) => point.y);
  }

  get backgroundColor() {
    return this.data!.map((point) => point.color);
  }

  get total() {
    return this.totalCount || this.dataset.reduce((sum, next) => sum + next, 0)
  }

  @Watch('data')
  onChangeData() {
    this.paintChart()
  }

  paintChart() {
    const ctx = (this.$refs[this.chartId] as HTMLCanvasElement).getContext('2d');

    const data = {
      datasets: [{
        label: '#',
        borderWidth: 0,
        backgroundColor: this.backgroundColor,
        data: this.dataset
      }]
    };

    const options: any = {
      cutoutPercentage: 80,
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        easing: 'easeInOutQuad',
        duration: 500
      },
      axes: {
        display: false
      },
      legend: {
        display: false
      },
      tooltips: {
        backgroundColor: '#364462',
        caretSize: 5,
        cornerRadius: 4,
        xPadding: 10,
        yPadding: 10,
        position: 'nearest',
        mode: 'point',
        displayColors: false,
        callbacks: {
          label: (tooltipItem, data) => {
            const ds = data.datasets[0];
            const percent = Math.round((ds.data[tooltipItem.index] / this.total) * 100)
            return percent + '%'
          }
        }
      }
    };

    this.chart = new Chart(ctx!, {
      type: 'doughnut',
      data,
      options
    });
  }

  mounted() {
    this.paintChart()
  }
};
