






























import {Component, Prop, Vue} from 'vue-property-decorator';
import StatsCard from '@/components/StatsCard.vue';
import StarRatingRow from '@/components/StarRatingRow.vue';
import {CustomersRatings} from '@/domain/model/types';

@Component({
  name: 'customer-rating-card',
  components: {StarRatingRow, StatsCard}
})
export default class CustomerRatingCard extends Vue {
  @Prop() item!: CustomersRatings

  get totalCount() {
    const {oneStar, twoStar, threeStar, fourStar, fiveStar} = this.item
    return oneStar + twoStar + threeStar + fourStar + fiveStar
  }

  get rating() {
    return parseFloat(this.item.avgRating.toFixed(1))
  }

  get fullStars() {
    return Math.floor(this.rating)
  }

  get hasSemiStar() {
    return this.rating % 1 > 0
  }

  get semiStarIcon() {
    const reminder = parseFloat((this.rating % 1).toFixed(2))
    return reminder >= 0.75 ? '_ic_star_75.svg' : (reminder >= 0.50 ? '_ic_star_50.svg' : '_ic_star_25.svg')
  }
}
