































import StatsCard from '@/components/StatsCard.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';
import SplineAreaChart from '@/components/SplineAreaChart.vue';
import {ResponseTime} from '@/domain/model/types';
import {formatMonthDay, msToTime} from '@/utils/helpers';
import ChartLine from '@/components/ChartLine.vue';
import {dashboardStore} from '@/store/modules/dashboard';
import {ChartData, ChartDataSets} from 'chart.js';

@Component({
  name: 'response-time-card',
  components: {ChartLine, SplineAreaChart, StatsCard}
})
export default class ResponseTimeCard extends Vue {
  @Prop() itemWeek!: Array<ResponseTime | null>
  @Prop() itemPrevWeek!: Array<ResponseTime | null>
  weekdays: string[] = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

  get customersStats() {
    return dashboardStore.dashboardComponent['customersStats']
  }

  get dataPoints() {
    const points: Array<number | null> = []
    for (let i = 0; i < this.itemWeek.length; i++) {
      const item = this.itemWeek[i];
      points.push(item !== null ? item.acceptTime : null)
    }
    return points
  }

  get dataPointsPrev() {
    const points: Array<number | null> = []
    for (let i = 0; i < this.itemPrevWeek.length; i++) {
      const item = this.itemPrevWeek[i];
      points.push(item !== null ? item.acceptTime : null)
    }
    return points
  }

  get tooltips() {
    const tips: Array<string[] | null> = []
    for (let i = 0; i < this.itemWeek.length; i++) {
      const item = this.itemWeek[i];
      tips.push(item !== null ? [formatMonthDay(item.createdDate?.toDate()), msToTime(item.acceptTime, true)] : null)
    }
    return tips
  }

  get tooltipsPrev() {
    const tips: Array<string[] | null> = []
    for (let i = 0; i < this.itemPrevWeek.length; i++) {
      const item = this.itemPrevWeek[i];
      tips.push(item !== null ? [formatMonthDay(item.createdDate?.toDate()), msToTime(item.acceptTime, true)] : null)
    }
    return tips
  }

  get averageTime() {
    return msToTime(dashboardStore.responseTimeAverage, true)
  }

  get averageTimePrev() {
    return msToTime(dashboardStore.responseTimePrevAverage, true)
  }

  get dynamics() {
    return dashboardStore.responseTimeDynamics
  }

  get timeDifference() {
    return `${this.dynamics}%`
  }

  get isPositive() {
    return this.dynamics <= 0
  }

  get iconDynamic() {
    return this.isPositive ? '_ic_arrow_dyn_pos.svg' : '_ic_arrow_dyn_neg.svg'
  }

  get chartData() {
    const ds1: ChartDataSets = {
      backgroundColor: '#88DE31',
      borderColor: '#88DE31',
      borderWidth: 4,
      data: this.dataPoints,
      fill: false,
      pointHoverBackgroundColor: '#88DE31',
      pointHoverBorderColor: '#fff',
      pointHoverRadius: 6,
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 3
    }
    const ds2: ChartDataSets = {
      borderColor: '#3773F5',
      borderWidth: 4,
      data: this.dataPointsPrev,
      fill: false,
      pointHoverBackgroundColor: '#3773F5',
      pointHoverBorderColor: '#fff',
      pointHoverRadius: 6,
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 3
    }
    return {
      labels: this.weekdays,
      datasets: [ds1, ds2] as ChartDataSets[]
    } as ChartData
  }
};
