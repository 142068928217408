









































import {Component, Prop, Vue} from 'vue-property-decorator'
import PopupMenu from '@/components/PopupMenu.vue'
import ToolTip from "@/components/custom/ToolTip.vue"

@Component({
  name: 'stats-card',
  components: {PopupMenu, ToolTip}
})
export default class StatsCard extends Vue {
  @Prop({default: false}) readonly withMenu?: boolean;
  @Prop({default: false}) readonly inset?: boolean;
  @Prop({default: 'Title'}) readonly title?: string;
  @Prop({default: 'Tip text'}) readonly tipText?: string;
  @Prop({default: null}) readonly label?: string;
}
