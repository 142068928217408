import store from '@/store'
import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {applicationStore} from '@/store/modules/application';
import {
  CasesCompletion,
  CaseType,
  CaseTypeData,
  CustomersQuantity,
  CustomersRatings,
  DashboardComponents,
  Interactions,
  LoadingStatus,
  ResponseTime
} from '@/domain/model/types';
import {collection, getDocs, limit, orderBy, query, where} from 'firebase/firestore';
import {
  businessCasesStats,
  businessCasesTypesStats,
  businessCustomersStats,
  businessInteractionsStats,
  businessRatingStats,
  businessResponseTimeStats,
  businessStaffPerformance
} from '@/data/firebase';


@Module({
  name: 'dashboard-store',
  store,
  dynamic: true
})
export default class DashboardStore extends VuexModule {
  private _customersQuantity: CustomersQuantity = {
    optedIn: 0,
    optedInDiff: 0,
    timestamp: null,
    total: 0,
    totalDiff: 0
  };
  private _customersRating: CustomersRatings = {
    avgRating: 0,
    createdDate: null,
    fiveStar: 0,
    fourStar: 0,
    oneStar: 0,
    threeStar: 0,
    totalRating: 0,
    twoStar: 0
  }
  private _casesCompletion: CasesCompletion = {
    closed: 0,
    createdDate: null,
    opened: 0
  }
  private _responseTime: ResponseTime = {
    diffResponseTime: 0,
    responseTime: 0,
    acceptTime: 0,
    createdDate: null,
    diffAcceptTime: 0,
    diffForwardTime: 0,
    diffRejectTime: 0,
    diffResolveTime: 0,
    forwardTime: 0,
    rejectTime: 0,
    resolveTime: 0,
    weekday: 0
  }
  private _responseTimeWeek: Array<ResponseTime | null> = []
  private _responseTimePrevWeek: Array<ResponseTime | null> = []

  private _interactions: Interactions = {
    createdDate: null,
    diffAppoints: 0,
    diffCases: 0,
    newAppoints: 0,
    openedCases: 0,
    weekday: 0
  }
  private _interactionsWeek: Array<Interactions | null> = []
  private _interactionsPrevWeek: Array<Interactions | null> = []
  private _caseType: CaseType[] = []
  private _staffPerformance: any[] = []

  private _dashboardLoading: DashboardComponents = {
    customersStats: LoadingStatus.NOT_LOADED,
    customersRatings: LoadingStatus.NOT_LOADED,
    casesCompletion: LoadingStatus.NOT_LOADED,
    reactionsTimes: LoadingStatus.NOT_LOADED,
    interactions: LoadingStatus.NOT_LOADED,
    caseTypes: LoadingStatus.NOT_LOADED,
    stuffPerformance: LoadingStatus.NOT_LOADED
  }

  get dashboardComponent() {
    return this._dashboardLoading
  }

  get customersQuantity() {
    return this._customersQuantity
  }

  get customersRating() {
    return this._customersRating
  }

  get casesCompletion() {
    return this._casesCompletion
  }

  get responseTime() {
    return this._responseTime
  }

  get responseTimeWeek() {
    return this._responseTimeWeek
  }

  get responseTimePrevWeek() {
    return this._responseTimePrevWeek
  }

  get responseTimeAverage() {
    if (!this._responseTimeWeek.length) {
      return 0
    }
    const nonNullValues = this._responseTimeWeek.filter((item) => item !== null);
    if (!nonNullValues.length) {
      return 0
    }
    return nonNullValues.reduce((sum, value) => sum + value!.acceptTime, 0) / nonNullValues.length
  }

  get responseTimePrevAverage() {
    if (!this._responseTimePrevWeek.length) {
      return 0
    }
    const nonNullValues = this._responseTimePrevWeek.filter((item) => item !== null);
    if (!nonNullValues.length) {
      return 0
    }
    return nonNullValues.reduce((sum, value) => sum + value!.acceptTime, 0) / nonNullValues.length
  }

  get responseTimeDynamics() {
    if (this.responseTimePrevAverage === 0 && this.responseTimeAverage === 0) {
      return 0;
    }
    if (this.responseTimePrevAverage === 0) {
      return 100;
    }
    return (100 * (this.responseTimeAverage / this.responseTimePrevAverage - 1)).toFixed(0)
  }

  get interactions() {
    return this._interactions
  }

  get interactionsWeek() {
    return this._interactionsWeek
  }

  get interactionsPrevWeek() {
    return this._interactionsPrevWeek
  }

  get totalCasesWeek() {
    if (!this._interactionsWeek.length) {
      return 0
    }
    const nonNullValues = this._interactionsWeek.filter((item) => item !== null);
    return nonNullValues.reduce((sum, value) => sum + value!.openedCases, 0)
  }

  get totalCasesPrevWeek() {
    if (!this._interactionsPrevWeek.length) {
      return 0
    }
    const nonNullValues = this._interactionsPrevWeek.filter((item) => item !== null);
    return nonNullValues.reduce((sum, value) => sum + value!.openedCases, 0)
  }

  get casesDynamics() {
    if (this.totalCasesPrevWeek === 0 && this.totalCasesWeek === 0) {
      return 0;
    }
    if (this.totalCasesPrevWeek === 0) {
      return 100;
    }
    return (100 * (this.totalCasesWeek / this.totalCasesPrevWeek - 1)).toFixed(0)
  }

  get caseType() {
    return this._caseType
  }

  get staffPerformance() {
    return this._staffPerformance
  }

  @Mutation
  public setCustomersQuantity(stats: CustomersQuantity) {
    this._customersQuantity = stats
  }

  @Mutation
  public setCustomersRating(stats: CustomersRatings) {
    this._customersRating = stats
  }

  @Mutation
  public setCasesCompletion(stats: CasesCompletion) {
    this._casesCompletion = stats
  }

  @Mutation
  public setResponseTime(stats: ResponseTime) {
    this._responseTime = stats
  }

  @Mutation
  public setResponseTimeWeek(stats: Array<ResponseTime | null>) {
    this._responseTimeWeek = stats
  }

  @Mutation
  public setResponseTimePrevWeek(stats: Array<ResponseTime | null>) {
    this._responseTimePrevWeek = stats
  }

  @Mutation
  public setInteractions(stats: Interactions) {
    this._interactions = stats
  }

  @Mutation
  public setInteractionsWeek(stats: Array<Interactions | null>) {
    this._interactionsWeek = stats
  }

  @Mutation
  public setInteractionsPrevWeek(stats: Array<Interactions | null>) {
    this._interactionsPrevWeek = stats
  }

  @Mutation
  public setCaseType(stats: CaseType[]) {
    this._caseType = stats
  }

  @Mutation
  public setStaffPerformance(stats: any[]) {
    this._staffPerformance = stats
  }

  @Mutation
  public setDashboardComponentStatus({component, status}) {
    this._dashboardLoading[component] = status
  }

  @Action
  public async loadDashboard() {
    const businessId = applicationStore.businessId!
    await Promise.all([this.loadCustomersStats(businessId),
      this.loadCustomersRatings(businessId),
      this.loadCasesCompletion(businessId),
      this.loadReactionsTimes(businessId),
      this.loadInteractions(businessId),
      this.loadCaseTypes(businessId),
      this.loadStuffPerformance(businessId)])
  }

  @Action
  public async loadStuffPerformance(businessId: string) {
    const ok = this.loadDashboardComponent('stuffPerformance')
    if (!ok) {
      return
    }
    const statsQuery = query(businessStaffPerformance(businessId),
      orderBy('createdDate', 'desc'),
      limit(1))
    const statsSnapshot = await getDocs(statsQuery)
    if (!statsSnapshot.empty) {
      const associateQuery = query(
        collection(statsSnapshot.docs[0].ref, 'associates'),
        orderBy('name'))
      const querySnapshot = await getDocs(associateQuery);
      const staff: any[] = querySnapshot.docs.map((doc) => doc.data())
      this.setStaffPerformance(staff)
      this.setDashboardComponentStatus({component: 'stuffPerformance', status: LoadingStatus.LOADED})
    }
  }

  @Action
  public async loadCaseTypes(businessId: string) {
    const ok = this.loadDashboardComponent('caseTypes')
    if (!ok) {
      return
    }
    const statsQuery = query(businessCasesTypesStats(businessId),
      orderBy('createdDate', 'desc'),
      limit(1))
    const statsSnapshot = await getDocs(statsQuery)
    if (!statsSnapshot.empty) {
      const caseTypeData: CaseTypeData = statsSnapshot.docs[0].data() as CaseTypeData;
      const sortedKeys: string[] = Object.keys(caseTypeData.cases).sort()
      const caseType: CaseType[] = []
      sortedKeys.forEach((key) => caseType.push({depName: key, numCases: caseTypeData.cases[key]}))
      this.setCaseType(caseType)
      this.setDashboardComponentStatus({component: 'caseTypes', status: LoadingStatus.LOADED})
    }
  }

  @Action
  public async loadInteractions(businessId: string) {
    const ok = this.loadDashboardComponent('interactions')
    if (!ok) {
      return
    }
    const statsQuery = query(businessInteractionsStats(businessId),
      orderBy('createdDate', 'desc'),
      limit(14))
    const statsSnapshot = await getDocs(statsQuery)
    if (!statsSnapshot.empty) {
      const interactions: Interactions[] = statsSnapshot.docs.map((doc) => doc.data() as Interactions);
      const lastDay: Interactions = interactions[0]
      this.setInteractions(lastDay)
      const lastWeekday = lastDay.weekday
      if (interactions.length > 7) {
        if (lastWeekday < 6) {
          const lastIndex = lastWeekday + 1
          const currentWeekInteractions: Array<Interactions | null> = interactions.slice(0, lastIndex).reverse()
          for (let index = currentWeekInteractions.length; index <= 6; index++) {
            currentWeekInteractions.push(null)
          }
          this.setInteractionsWeek(currentWeekInteractions)
          this.setInteractionsPrevWeek(interactions.slice(lastIndex, lastIndex + 7).reverse())
        } else {
          const reversedInteractions = interactions.reverse()
          this.setInteractionsWeek(reversedInteractions.slice(0, 7))
          this.setInteractionsPrevWeek(reversedInteractions.slice(7, 14))
        }
      } else {
        if (lastWeekday < 6) {
          const lastIndex = lastWeekday + 1
          const currentWeekInteractions: Array<Interactions | null> = interactions.slice(0, lastIndex).reverse()
          for (let index = currentWeekInteractions.length; index <= 6; index++) {
            currentWeekInteractions.push(null)
          }
          this.setInteractionsWeek(currentWeekInteractions)
        } else {
          this.setInteractionsWeek(interactions.reverse())
        }
        this.setInteractionsPrevWeek([null, null, null, null, null, null, null])
      }
      this.setDashboardComponentStatus({component: 'interactions', status: LoadingStatus.LOADED})
    }
  }

  @Action
  public async loadReactionsTimes(businessId: string) {
    const ok = this.loadDashboardComponent('reactionsTimes')
    if (!ok) {
      return
    }
    const statsQuery = query(businessResponseTimeStats(businessId),
      orderBy('createdDate', 'desc'),
      limit(14))
    const statsSnapshot = await getDocs(statsQuery)
    if (!statsSnapshot.empty) {
      const responseTimes: ResponseTime[] = statsSnapshot.docs.map((doc) => doc.data() as ResponseTime);
      const lastDay: ResponseTime = responseTimes[0];
      this.setResponseTime(lastDay)
      const lastWeekday: number = lastDay.weekday
      if (responseTimes.length > 7) {
        if (lastWeekday < 6) {
          const lastIndex = lastWeekday + 1;
          const currentWeekTimes: Array<ResponseTime | null> = responseTimes.slice(0, lastIndex).reverse();
          for (let index = currentWeekTimes.length; index <= 6; index++) {
            currentWeekTimes.push(null)
          }
          this.setResponseTimeWeek(currentWeekTimes)
          this.setResponseTimePrevWeek(responseTimes.slice(lastIndex, lastIndex + 7).reverse())

        } else {
          const reversedTimes = responseTimes.reverse()
          this.setResponseTimePrevWeek(reversedTimes.slice(0, 7))
          this.setResponseTimeWeek(reversedTimes.slice(7, 14))
        }
      } else {
        if (lastWeekday < 6) {
          const lastIndex = lastWeekday + 1;
          const currentWeekTimes: Array<ResponseTime | null> = responseTimes.slice(0, lastIndex).reverse();
          for (let index = currentWeekTimes.length; index <= 6; index++) {
            currentWeekTimes.push(null)
          }
          this.setResponseTimeWeek(currentWeekTimes)
        } else {
          this.setResponseTimeWeek(responseTimes.reverse())
        }
        this.setResponseTimePrevWeek([null, null, null, null, null, null, null])
      }
      this.setDashboardComponentStatus({component: 'reactionsTimes', status: LoadingStatus.LOADED})
    }
  }

  @Action
  public async loadCasesCompletion(businessId: string) {
    const ok = this.loadDashboardComponent('casesCompletion')
    if (!ok) {
      return
    }
    const statsQuery = query(businessCasesStats(businessId),
      orderBy('createdDate', 'desc'),
      limit(1))
    const statsSnapshot = await getDocs(statsQuery)
    if (!statsSnapshot.empty) {
      const documentData: CasesCompletion = statsSnapshot.docs[0].data() as CasesCompletion;
      this.setCasesCompletion(documentData)
      this.setDashboardComponentStatus({component: 'casesCompletion', status: LoadingStatus.LOADED})
    }
  }

  @Action
  public async loadCustomersRatings(businessId: string) {
    const ok = this.loadDashboardComponent('customersRatings')
    if (!ok) {
      return
    }
    const statsQuery = query(businessRatingStats(businessId),
      orderBy('createdDate', 'desc'),
      limit(1))
    const statsSnapshot = await getDocs(statsQuery)
    if (!statsSnapshot.empty) {
      const documentData: CustomersRatings = statsSnapshot.docs[0].data() as CustomersRatings;
      this.setCustomersRating(documentData)
      this.setDashboardComponentStatus({component: 'customersRatings', status: LoadingStatus.LOADED})
    }
  }

  @Action
  public async loadCustomersStats(businessId: string) {
    const ok = this.loadDashboardComponent('customersStats')
    if (!ok) {
      return
    }
    const statsQuery = query(businessCustomersStats(businessId!),
      where('timestamp', '!=', null),
      orderBy('timestamp', 'desc'),
      limit(1))
    const statsSnapshot = await getDocs(statsQuery)
    if (!statsSnapshot.empty) {
      const documentData: CustomersQuantity = statsSnapshot.docs[0].data() as CustomersQuantity;
      this.setCustomersQuantity(documentData)
      this.setDashboardComponentStatus({component: 'customersStats', status: LoadingStatus.LOADED})
    }
  }

  @Action
  public loadDashboardComponent(component) {
    if (this._dashboardLoading[component] !== LoadingStatus.NOT_LOADED) {
      return
    }
    this.setDashboardComponentStatus({component, status: LoadingStatus.LOADING})
    return true
  }
}

export const dashboardStore = getModule(DashboardStore)
