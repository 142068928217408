











import StatsCard from '@/components/StatsCard.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';
import DoughnutPieChart from '@/components/DoughnutPieChart.vue';
import {CaseType} from '@/domain/model/types';

@Component({
  name: 'case-type-card',
  components: {DoughnutPieChart, StatsCard}
})
export default class CaseTypeCard extends Vue {
  @Prop() item!: CaseType[]

  classes: string[] = ['one', 'two', 'three', 'four', 'five']
  colors: string[] = ['#88DE31', '#3773F5', '#FA511F', '#37C6F5', '#A4A4A4']

  get dataPoints() {
    return this.item.slice(0, 5).map((item, index) => ({
      y: item.numCases,
      color: this.colors[index % 5],
      title: item.depName,
      class: this.classes[index % 5],
      tipValue: `${item.numCases}%`
    }))
  }
};
