


















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'recommendation'})
export default class Recommendation extends Vue {
  @Prop() value!: boolean;
  @Prop() message?: string;

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }
};
