


































































































import {Component} from 'vue-property-decorator';
import StatsCard from '@/components/StatsCard.vue';
import CompletionCard from '@/components/CompletionCard.vue';
import CaseTypeCard from '@/components/CaseTypeCard.vue';
import ResponseTimeCard from '@/components/ResponseTimeCard.vue';
import InteractionsCard from '@/components/InteractionsCard.vue';
import CustomerRatingCard from '@/components/CustomerRatingCard.vue';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import CustomerQuantityCard from '@/components/CustomerQuantityCard.vue';
import Recommendation from '@/components/Recommendation.vue';
import PopupMenu from '@/components/PopupMenu.vue';
import {dashboardStore} from '@/store/modules/dashboard';
import {msToTime} from '@/utils/helpers';
import ChartLine from '@/components/ChartLine.vue';
import {mixins} from "vue-class-component";
import ContainerCalc from "@/components/mixins/ContainerCalc";
import GpTable from '@/components/custom/TableView.vue';

@Component({
  name: 'dashboard',
  components: {
    ChartLine,
    PopupMenu,
    Recommendation,
    CustomerQuantityCard,
    AvatarWithStatus,
    CustomerRatingCard,
    InteractionsCard,
    ResponseTimeCard,
    CaseTypeCard,
    CompletionCard,
    StatsCard,
    GpTable
  },
  filters: {msToTime}
})
export default class Dashboard extends mixins(ContainerCalc) {
  selected: any[] = []
  headerTable: any = {
    sort: {index: 0, order: 'asc'},
    width: {},
    list: [
      {text: 'Name', sortable: true, value: 'name'},
      {text: 'activity', sortable: false},
      {text: 'closed cases', sortable: false},
      {text: 'accept time', sortable: false},
      {text: 'response time', sortable: false},
      {text: 'resolve time', sortable: false},
      {text: 'rate', sortable: false},
    ]
  }

  get customersQuantity() {
    return dashboardStore.customersQuantity
  }

  get customersRating() {
    return dashboardStore.customersRating
  }

  get casesCompletion() {
    return dashboardStore.casesCompletion
  }

  get responseTimeWeek() {
    return dashboardStore.responseTimeWeek
  }

  get responseTimePrevWeek() {
    return dashboardStore.responseTimePrevWeek
  }

  get interactions() {
    return dashboardStore.interactions
  }

  get interactionsWeek() {
    return dashboardStore.interactionsWeek
  }

  get interactionsPrevWeek() {
    return dashboardStore.interactionsPrevWeek
  }

  get caseType() {
    return dashboardStore.caseType
  }

  get associates() {
    return dashboardStore.staffPerformance
  }

  get headers() {
    return [
      {
        text: 'name',
        asc: true,
        style: {width: '260px', maxWidth: '260px'},
        tip: 'User\'s name'
      },
      {
        text: 'activity',
        asc: true,
        style: {width: '125px', maxWidth: '125px'},
        tip: 'The total amount of time this user has been active'
      },
      {
        text: 'closed cases',
        asc: true,
        style: {width: '135px', maxWidth: '135px'},
        tip: 'The total number of cases that were closed'
      },
      {
        text: 'accept time',
        asc: true,
        style: {width: '125px', maxWidth: '125px'},
        tip: 'The average amount of time taken to accept a case'
      },
      {
        text: 'response time',
        asc: true,
        style: {width: '125px', maxWidth: '125px'},
        tip: 'The average amount of time taken to send an initial response'
      },
      {
        text: 'resolve time',
        asc: true,
        style: {width: '125px', maxWidth: '125px'},
        tip: 'The average amount of time taken to close a case'
      },
      {
        text: 'rate',
        asc: true,
        style: {width: '80px', maxWidth: '80px'},
        tip: 'The average number of stars based on customer-submitted ratings'
      }
    ];
  }

  get suggestions() {
    return [
      // {
      //   id: 1,
      //   message: 'Overall KPI increased by 37 %',
      //   show: true
      // },
      // {
      //   id: 2,
      //   message: 'New Car Sales Group decreased KPI by 22 %',
      //   show: true
      // }
    ];
  }

  onSort(index, order) {
    this.headerTable.sort = {index: index, order: order};
  }

  associateRating(item: any) {
    return item.rating?.toFixed(1) || 0.0
  }

  mounted() {
    this.calcHeight(this.$refs.borderDiv)
  }

  updated() {
    this.calcHeight(this.$refs.borderDiv)
  }

  async created() {
    dashboardStore.loadDashboard()
  }
}
