
import {Component, Prop} from 'vue-property-decorator';
import {Line, mixins as MixinsChart} from 'vue-chartjs'
import {ChartOptions} from 'chart.js';
import {mixins} from 'vue-class-component';

@Component({
  name: 'chart-line'
})
export default class ChartLine extends mixins(Line, MixinsChart.reactiveProp) {
  @Prop() readonly tooltips!: Array<Array<string[]>>

  chartOptions: ChartOptions = {
    layout: {padding: 16},
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      easing: 'easeInOutQuad',
      duration: 500
    },
    legend: {
      display: false
    },
    title: {
      display: false
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: true,
            borderDash: [7, 7],
            lineWidth: 1,
            color: ['rgb(54,68,98,0.2)', 'rgb(54,68,98,0.2)', 'rgb(54,68,98,0.2)', 'rgb(54,68,98,0.2)',
              'rgb(54,68,98,0.2)', 'rgb(54,68,98,0.2)', 'rgb(54,68,98,0.2)']
          },
          ticks: {
            fontColor: 'rgb(54,68,98,0.4)',
            fontSize: 7,
            fontStyle: 'bold'
          }
        }
      ],
      yAxes: [{
        display: false
      }]
    },
    tooltips: {
      backgroundColor: '#364462',
      caretSize: 4,
      cornerRadius: 4,
      xPadding: 10,
      yPadding: 4,
      position: 'nearest',
      mode: 'point',
      displayColors: false,
      bodyFontSize: 10,
      bodyFontColor: '#fff',
      bodyFontStyle: 'bold',
      bodyAlign: 'center',
      callbacks: {
        title(item: Chart.ChartTooltipItem[], data: Chart.ChartData): string | string[] {
          return ''
        },
        label: (tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] => {
          const datasetIndex = tooltipItem.datasetIndex;
          const index = tooltipItem.index;
          if (datasetIndex === undefined || index === undefined) {
            return ''
          }
          return this.tooltips[datasetIndex][index]
        }
      }
    }
  }

  mounted() {
    const {chartData, chartOptions} = this
    this.renderChart(chartData, chartOptions)
  }
}
