
















import StatsCard from '@/components/StatsCard.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'customer-quantity-card',
  components: {StatsCard}
})
export default class CustomerQuantityCard extends Vue {
  @Prop({default: 0}) readonly total!: number
  @Prop({default: 0}) readonly totalDiff!: number

  get showDiff() {
    return this.totalDiff > 0
  }
};
