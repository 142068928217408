













import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'star-rating-row'})
export default class StarRatingRow extends Vue {
  @Prop({default: 0}) stars!: number;
  @Prop({default: 0}) count!: number;
  @Prop({default: 0}) total!: number;

  get widthStyle() {
    const percent = (1.3 * 70 * this.count / this.total).toFixed(0)
    return {
      width: `${percent}%`,
      maxWidth: '70%'
    }
  }

  get color() {
    return this.count > 0 ? 'primary' : 'grey'
  }
}
